import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import { PageLayout, Seo } from '../components'
import logo from '../../static/logoColor.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > h1 {
    font-family: Koulen;
    font-size: 58px;
    margin: 0;
    line-height: 48px;
  }
  > img {
    max-width: 350px;
    @media only screen and (max-width: 600px) {
      max-width: 300px;
    }
  }
`

const Catchphrase = styled.p`
  font-size: 1.25rem;
`

const MenuButton = styled(Link)`
  border: 1px solid #fc0000;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: #fc0000;
  text-decoration: none;
  font-family: Koulen;
  font-size: 1.25rem;
  color: white;
  :visited {
    color: white;
  }
  :hover {
    background-color: #e2e2e2;
    background-color: #ff2929;
  }
`
const IndexPage: React.FC = () => {
  return (
    <Seo title="Home">
      <PageLayout>
        <Container>
          <img src={logo} />
          <Catchphrase>
            Serving funnel cakes and fried food that is out of this world!
          </Catchphrase>
          <MenuButton to="/menu">See Menu</MenuButton>
        </Container>
      </PageLayout>
    </Seo>
  )
}

export default IndexPage
